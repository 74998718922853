import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CampaignPoints from "./pages/campaign_points";
import Redirect from "./pages/redirect";
import { PATH } from "./util/path";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATH.CAMPAIGN_POINTS} element={<CampaignPoints />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
