/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Buffer } from "buffer";

const Page: FC = () => {
  const [yesterday_points, set_yesterday_points] = useState<string | number>("-");
  const [current_points, set_current_points] = useState("-");
  const [updated, set_updated] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const search = useLocation().search;
  const query_base64 = new URLSearchParams(search).get("requests");
  const query_decoded = query_base64 ? Buffer.from(query_base64, "base64").toString("utf-8") : "";
  const params = new URLSearchParams(query_decoded);
  const subscription_id = params.get("subscription_id");
  const user_number = params.get("user_number");
  const user_name = params.get("user_name");
  const getPointsUrl = `${process.env.REACT_APP_POINTS_API_URL}${subscription_id}/${user_number}/points`;
  const postPointsUrl = `${process.env.REACT_APP_POINTS_API_URL}points/${subscription_id}/${user_number}`;

  const date = new Date();
  const dateString = date.getFullYear() + "年" + (date.getMonth() + 1) + "月";

  const getPoints = () => {
    axios
      .get(getPointsUrl)
      .then((res) => {
        set_yesterday_points(res.data.yesterday_points);
        set_current_points(res.data.current_points);
        set_updated(res.data.updated);
        setDisabled(res.data.updated);
      })
      .catch(() => {
        alert("システムエラーが発生し、情報取得に失敗しました。時間を空けて再度お試しください。");
      });
  };

  const postPoints = () => {
    setDisabled(true);
    axios
      .post(postPointsUrl)
      .then(() => {
        getPoints();
      })
      .catch(() => {
        alert("システムエラーが発生し、情報取得に失敗しました。時間を空けて再度お試しください。");
        setDisabled(false);
      });
  };

  useEffect(() => {
    getPoints();
  }, []);

  return (
    <>
      <Helmet>
        <title>ビーコンキャンペーン</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="Container">
        <div className="Header">
          <div className="Header__inner">
            <div className="Header__logo">Beacon Point campaign</div>
          </div>
        </div>
        <div className="Main">
          <div className="Main__inner">
            <div className="UserInfo">
              <div className="UserInfo__id">{user_number}</div>
              <div className="UserInfo__name">{user_name}</div>
            </div>
            <div className="BeaconPt">
              <div className="BeaconPt__total">
                <div className="BeaconPt__icon">
                  <img src={process.env.PUBLIC_URL + "/img/icon_beacon.svg"} alt="" />
                </div>
                <p>Total</p>
                <p className="BeaconPt__count">
                  {current_points}
                  <span>pt</span>
                </p>
                <p className="BeaconPt__date">{dateString}</p>
              </div>
              <div className="BeaconPt__new">
                <p>昨日のBeacon Pt</p>
                <p className="BeaconPt__new-count">
                  {yesterday_points}
                  <span>pt</span>
                </p>
                {yesterday_points !== "-" && yesterday_points > 0 && (
                  <button
                    className={`BeaconPt__button ${disabled && "-already"}`}
                    disabled={disabled}
                    onClick={() => postPoints()}
                  >
                    {updated ? "獲得済み" : "GET!"}
                  </button>
                )}
              </div>
              <div className="BeaconPt__footer footer_margin">
                <a href={process.env.REACT_APP_CONTACT_URL} target="_blank" rel="noreferrer">
                  キャンペーンの内容・お問い合わせ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
